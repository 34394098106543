
import { Component } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import Header from "@/components/Header.vue";
import { CreateHospital } from "@/@types/management";
import FacilityService from "@/services/facilities";
import { deleteNullProp, getPhoneNumber3, parsePhoneNumber } from "@/utils/Util";
import CheckAdminLogin from "@/utils/CheckAdminLogin";
import FormSet from "@/components/FormSet.vue";
import Button from "@/components/Button.vue";

@Component({
  name: "FacilityManagementHospital",
  components: {
    Table,
    Header,
    ManagementFooter,
    FormSet,
    Button,
  },
})
export default class FacilityManagementHospital extends CheckAdminLogin {
  private routeName = this.$route.name;
  private loading = false;
  private animalTypes = [];
  private form: CreateHospital = {
    id: "",
    name: null,
    tel: null,
    address: null,
    director: "-",
    email: "",
    url: null,
    access: null,
    must_memo: false,
    animal_types: [],
    doctor_schedule: null,
    contract_id: null,
    has_notification_new: true,
    has_notification_cancel: true,
    has_notification_remind: true,
    has_notification_update: true,
  };
  private phoneNumber = {
    number1: "",
    number2: "",
    number3: "",
  };
  private get isEdit(): boolean {
    return this.routeName === "FacilityManagementHospitalEdit";
  }
  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async submit() {
    try {
      this.loading = true;
      this.form.tel =
        this.phoneNumber.number1 && this.phoneNumber.number2 && this.phoneNumber.number3
          ? parsePhoneNumber(this.phoneNumber.number1 + this.phoneNumber.number2 + this.phoneNumber.number3)
          : "";
      if (this.routeName === "FacilityManagementHospitalEdit") {
        const id = this.$route.params.id;
        const payload = deleteNullProp(this.form);
        await FacilityService.update(id, payload);
        this.$router.push({ name: "FacilityManagementDetail", params: { facility_id: id } });
      } else {
        delete this.form.id;
        const payload = deleteNullProp(this.form);
        const res = await FacilityService.create(payload);
        if (res.data && res.data.data && res.data.data[0] && res.data.data[0].id) {
          this.$router.push({ name: "FacilityManagement" });
        }
      }
      this.loading = false;
    } catch (error: any) {
      if (error.response && error.response.data.error_msg.includes("contract_id")) {
        this.showModal("contractIdError");
      } else {
        this.showModal("saveError");
      }
      this.loading = false;
    }
  }

  private async getAnimalTypes() {
    try {
      const res = await FacilityService.getAnimalTypes();
      this.animalTypes = res.data.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }
  private async getHospitalInfo() {
    if (this.$route.params.id) {
      try {
        this.loading = true;
        const res = await FacilityService.get(this.$route.params.id);
        if (res.data.data[0]) {
          this.form = res.data.data[0];
        }
        if (this.form.tel) this.phoneNumber = getPhoneNumber3(this.form.tel);
        this.loading = false;
      } catch (error: any) {
        this.loading = false;
        throw new Error(error);
      }
    }
  }

  private mounted() {
    this.getAnimalTypes();
    if (this.$route.name === "FacilityManagementHospitalEdit") this.getHospitalInfo();
  }

  private back() {
    this.$router.push({ name: "FacilityManagementDetail", params: { facility_id: this.$route.params.id } });
  }
}
